import { jsx as _jsx } from "react/jsx-runtime";
import { NavLink, Navigate } from 'react-router-dom';
import { NoAccess, NotFound } from 'shared/components';
import { SvgIcon } from 'core/ui';
import { createBrowserRouter } from 'react-router-dom';
import { getPackingTypeNameById } from 'shared/helpers';
import houseIcon from 'core/svg/fa/house.svg';
import { t } from 'shared/translations';
// Multiple Routes in a single file.
// If you want to avoid loading multiple chunks for **nested** routes, you could store them all in the same file and return them to the individual routes.
// Modern bundlers will latch onto the same Promise for the different import() invocations.
// Example below: <CrossdockingReceipt /> and <CrossdockingReceiptItems /> both have the same import path and therefore have a single chunk.
export const router = createBrowserRouter([
    {
        path: 'login',
        lazy: async () => {
            const { Login } = await import('shared/modules/auth/Login');
            return { Component: Login };
        },
    },
    {
        path: '/',
        // element: (
        // 	<RequireAuth>
        // 		<Layout sidebarNavigationItems={sidebarNavigationItems} />
        // 	</RequireAuth>
        // ),
        lazy: async () => {
            const { AuthorizedLayout } = await import('warehouse/navigation/AuthorizedLayout');
            return { Component: AuthorizedLayout };
        },
        handle: {
            crumb: () => (_jsx(NavLink, { to: "/", title: "home", children: _jsx(SvgIcon, { icon: houseIcon, size: "sm" }) })),
        },
        children: [
            // // New blog index route
            // { index: true, Component: () => <h1>Blog Index</h1> },
            // // Blog subapp splat route added for /blog/posts matching
            // { path: "*", Component: BlogApp },
            {
                path: '/',
                element: _jsx(Navigate, { to: "receipt/cross", replace: true }),
            },
            // TODO: to delete later (good working solution without signalR added)
            // { path: 'receipt/cross', element: <Navigate to="invoice" replace /> },
            // {
            // 	path: 'receipt/cross/:tab',
            // 	lazy: async () => {
            // 		const { Receipt, ApproveReceiptItemDialog, BlockReceiptItemDialog } = await import('warehouse/modules/receipt');
            // 		return {
            // 			element: (
            // 				<>
            // 					<Receipt />
            // 					<ApproveReceiptItemDialog />
            // 					<BlockReceiptItemDialog />
            // 				</>
            // 			),
            // 		};
            // 	},
            // },
            // {
            // 	path: 'receipt/cross/:tab/:invoiceId',
            // 	lazy: async () => {
            // 		const { Invoice } = await import('warehouse/modules/receipt/components/Inbox');
            // 		const { ApproveReceiptItemDialog, BlockReceiptItemDialog, RevokeReceiptItemDialog } = await import('warehouse/modules/receipt');
            // 		return {
            // 			element: (
            // 				<>
            // 					<Invoice />
            // 					<ApproveReceiptItemDialog />
            // 					<BlockReceiptItemDialog />
            // 					<RevokeReceiptItemDialog />
            // 				</>
            // 			),
            // 		};
            // 	},
            // },
            { path: 'receipt/cross', element: _jsx(Navigate, { to: "inbox", replace: true }) },
            {
                path: 'receipt/cross',
                lazy: async () => {
                    const { CrossdockingReceiptContainer } = await import('warehouse/modules/receipt');
                    return { Component: CrossdockingReceiptContainer };
                },
                handle: {
                    crumb: () => _jsx(NavLink, { to: "/receipt/cross", children: `${t('receipt.label')} ${t('crossdocking.label').toLocaleLowerCase()}` }),
                },
                children: [
                    {
                        path: ':tab',
                        lazy: async () => {
                            const { CrossdockingReceipt } = await import('warehouse/modules/receipt');
                            return { Component: CrossdockingReceipt };
                        },
                        handle: {
                            crumb: ({ params }) => (_jsx(NavLink, { to: `/receipt/cross/${params.tab}`, children: params.tab === 'inbox' ? t('incoming.label') : params.tab === 'verification' ? t('verification.label') : 'UNKNOWN' })),
                        },
                    },
                    {
                        path: ':tab/:invoiceId',
                        lazy: async () => {
                            const { Invoice } = await import('warehouse/modules/receipt/crossdocking/components/Invoice');
                            return { Component: Invoice };
                        },
                        handle: {
                            crumb: ({ params }) => (_jsx(NavLink, { to: `/receipt/cross/${params.tab}`, relative: "path", children: t('incoming.label') })
                            // TODO: to delete the comments later
                            // Workaround, since the route is not descendant of route above
                            // <Box display="inline-flex" margin="0 .5rem" color={(theme) => theme.palette.text.disabled}>
                            // 	<BreadcrumbSeparator />
                            // </Box>
                            // <NavLink to={`/receipt/cross/${params.tab}/${params.invoiceId}`} relative="path">
                            // 	{t('utd.abbr')}
                            // </NavLink>
                            ),
                        },
                    },
                ],
            },
            { path: 'placement', element: _jsx(Navigate, { to: "receipted", replace: true }) },
            {
                path: 'placement',
                lazy: async () => {
                    const { Placement } = await import('warehouse/modules/placement');
                    return { Component: Placement };
                },
                handle: {
                    crumb: () => _jsx(NavLink, { to: "/placement/receipted", children: t('placement.label') }),
                },
                children: [
                    {
                        path: ':tab',
                        lazy: async () => {
                            const { Placement } = await import('warehouse/modules/placement');
                            return { Component: Placement };
                        },
                        handle: {
                            crumb: ({ params: { tab } }) => _jsx(NavLink, { to: `/placement/${tab}`, children: t(`${tab}.label`) }),
                        },
                    },
                ],
            },
            {
                path: 'packing',
                // lazy: async () => {
                // 	const { Packing } = await import('warehouse/modules/packing');
                // 	return { Component: Packing };
                // },
                handle: {
                    crumb: () => _jsx(NavLink, { to: "/packing", children: t('packing.label') }),
                },
                children: [
                    {
                        index: true,
                        lazy: async () => {
                            const { Packing } = await import('warehouse/modules/packing');
                            return { Component: Packing };
                        },
                    },
                    {
                        path: ':packingType/:customerId/:legalId',
                        lazy: async () => {
                            const { PackingDetails } = await import('warehouse/modules/packing');
                            return { Component: PackingDetails };
                        },
                        handle: {
                            crumb: ({ params: { packingType, customerId, legalId } }) => {
                                const packingTypeName = getPackingTypeNameById(Number(packingType));
                                return (
                                // <NavLink to={`${packingType}/${customerId}/${legalId}`} relative="path">
                                _jsx(NavLink, { to: `/packing/${packingType}/${customerId}/${legalId}`, children: packingTypeName }));
                            },
                        },
                    },
                ],
            },
            {
                path: 'shipment',
                lazy: async () => {
                    const { ShipmentContainer } = await import('warehouse/modules/shipment');
                    return { Component: ShipmentContainer };
                },
                handle: {
                    crumb: () => _jsx(NavLink, { to: "/shipment", children: t('shipment.label') }),
                },
            },
            { path: 'receipt/stock', element: _jsx(Navigate, { to: "inbox", replace: true }) },
            {
                path: 'receipt/stock',
                lazy: async () => {
                    const { StockReceiptContainer } = await import('warehouse/modules/receipt');
                    return { Component: StockReceiptContainer };
                },
                handle: {
                    crumb: () => _jsx(NavLink, { to: "/receipt/stock", children: `${t('receipt.label')} ${t('stock.label').toLocaleLowerCase()}` }),
                },
                children: [
                    {
                        path: ':tab',
                        lazy: async () => {
                            const { StockReceipt } = await import('warehouse/modules/receipt');
                            return { Component: StockReceipt };
                        },
                        handle: {
                            crumb: ({ params }) => (_jsx(NavLink, { to: `/receipt/stock/${params.tab}`, children: params.tab === 'inbox' ? t('incoming.label') : params.tab === 'verification' ? t('verification.label') : 'UNKNOWN' })),
                        },
                    },
                    {
                        path: ':tab/:invoiceId',
                        lazy: async () => {
                            const { Invoice } = await import('warehouse/modules/receipt/stock/components/Invoice');
                            return { Component: Invoice };
                        },
                        handle: {
                            crumb: ({ params }) => (_jsx(NavLink, { to: `/receipt/stock/${params.tab}`, relative: "path", children: t('incoming.label') })),
                        },
                    },
                ],
            },
            {
                path: 'stock',
                //element: <StockReceipt /> ,
                // TODO: allow access only to 'Warehouse manager' (uncomment)
                // element: <ProtectedRoute allow={'Warehouse'} />,
                handle: {
                    crumb: () => _jsx(NavLink, { to: "/stock", children: t('warehouses.label') }),
                },
                children: [
                    {
                        index: true,
                        // TODO: allow access only to 'Warehouse manager' (uncomment)
                        // element: <ProtectedRoute allow={'Warehouse'} />,
                        lazy: async () => {
                            const { StockList } = await import('warehouse/modules/stock');
                            return { Component: StockList };
                        },
                    },
                    {
                        path: ':stockId',
                        lazy: async () => {
                            const { Stock } = await import('warehouse/modules/stock');
                            return { Component: Stock };
                        },
                        handle: {
                            crumb: ({ params }) => _jsx(NavLink, { to: `/stock/${params.stockId}`, children: t('stock.label') }),
                        },
                    },
                ],
            },
            {
                path: 'settings',
                lazy: async () => {
                    const { Settings } = await import('warehouse/modules/settings');
                    return { Component: Settings };
                },
            },
            { path: 'access', element: _jsx(NoAccess, {}) },
            { path: '*', element: _jsx(NotFound, {}) },
        ],
    },
]);
