import { DetailArticlePosition, UploadStockInvoiceStepIndex, } from '../models';
import { createSelector, createSlice } from '@reduxjs/toolkit';
// TODO: to delete later
/** Settings returned by api (the result of `stockInvoiceParsing/settings` GET query) if there are no settings for the user yet. */
// const userEmptySettings = {
// 	invoiceIdCell: {
// 		column: 0,
// 		row: 0,
// 	},
// 	dateCell: {
// 		column: 0,
// 		row: 0,
// 	},
// 	articleColumn: {
// 		position: 1,
// 		column: 0,
// 	},
// 	startRow: 0,
// 	qtyColumn: 0,
// 	priceColumn: 0,
// 	brandColumn: 0,
// 	nameColumn: 0,
// 	countryCodeColumn: 0,
// 	gtdColumn: 0,
// 	trackingCodeColumn: 0,
// 	trackingQtyColumn: 0,
// 	trackingSymbolColumn: 0,
// };
const userEmptySettings = {
    invoiceIdCell: undefined,
    dateCell: undefined,
    articleColumn: undefined,
    startRow: undefined,
    qtyColumn: undefined,
    priceColumn: undefined,
    brandColumn: undefined,
    nameColumn: undefined,
    countryCodeColumn: undefined,
    gtdColumn: undefined,
    trackingCodeColumn: undefined,
    trackingQtyColumn: undefined,
    trackingSymbolColumn: undefined,
};
const initialState = {
    inboxDateRangeFilter: {
        // Should always be `null`, otherwise it fires additional request with this initial values right before the
        // necessary request with values gotten from local storage.
        dateFrom: null,
        dateTo: null,
    },
    inboxArticleSearchTerm: '',
    inboxItemCount: undefined,
    verificationItemCount: undefined,
    ownerLocation: 'invoice',
    placeStockReceiptItemDialog: {
        open: false,
        data: undefined,
    },
    uploadInvoiceDialog: {
        open: false,
        activeStep: UploadStockInvoiceStepIndex.StockSelect,
        invoice: undefined,
        isInvoiceUploading: false,
        invoiceUploadErrorMessage: undefined,
        // Handles the case if no settings returned (undefined or null), that is how it should be in new backend, probably.
        // invoiceSettings: {
        // 	// invoiceIdCell: {
        // 	// 	column: undefined,
        // 	// 	row: undefined,
        // 	// },
        // 	// dateCell: {
        // 	// 	column: undefined,
        // 	// 	row: undefined,
        // 	// },
        // 	invoiceIdCell: undefined,
        // 	dateCell: undefined,
        // 	// articleColumn: {
        // 	// 	position: DetailArticlePosition.WholeString,
        // 	// 	column: undefined,
        // 	// },
        // 	articleColumn: undefined,
        // 	startRow: undefined,
        // 	qtyColumn: undefined,
        // 	priceColumn: undefined,
        // 	brandColumn: undefined,
        // 	nameColumn: undefined,
        // 	countryCodeColumn: undefined,
        // 	gtdColumn: undefined,
        // 	trackingCodeColumn: undefined,
        // 	trackingQtyColumn: undefined,
        // 	trackingSymbolColumn: undefined,
        // },
        invoiceSettings: userEmptySettings,
        activeSettingKey: 'invoiceIdCell',
    },
};
const slice = createSlice({
    name: 'stockReceipt',
    initialState,
    reducers: {
        setInboxDateRangeFilter: (state, action) => {
            state.inboxDateRangeFilter = action.payload;
        },
        setInboxItemCount: (state, action) => {
            state.inboxItemCount = action.payload;
        },
        setVerificationItemCount: (state, action) => {
            state.verificationItemCount = action.payload;
        },
        setInboxArticleSearchTerm: (state, action) => {
            state.inboxArticleSearchTerm = action.payload;
        },
        setOwnerLocation: (state, action) => {
            state.ownerLocation = action.payload;
        },
        openPlaceReceiptItemDialog: (state, action) => {
            state.placeStockReceiptItemDialog = {
                ...state.placeStockReceiptItemDialog,
                open: true,
                data: {
                    ...action.payload,
                },
            };
        },
        closePlaceReceiptItemDialog: (state) => {
            state.placeStockReceiptItemDialog = initialState.placeStockReceiptItemDialog;
        },
        openUploadInvoiceDialog: (state, action) => {
            state.uploadInvoiceDialog.open = true;
        },
        closeUploadInvoiceDialog: (state) => {
            state.uploadInvoiceDialog = initialState.uploadInvoiceDialog;
        },
        setStep: (state, action) => {
            state.uploadInvoiceDialog.activeStep = action.payload;
        },
        setIsInvoiceUploading: (state, action) => {
            state.uploadInvoiceDialog.isInvoiceUploading = action.payload;
        },
        setInvoiceSettings: (state, action) => {
            state.uploadInvoiceDialog.invoiceSettings = action.payload ?? userEmptySettings;
        },
        setInvoiceSettingsArticlePosition: (state, action) => {
            // `articleColumn.column` field must already been set, and `articleColumn` is already not null.
            if (!state.uploadInvoiceDialog.invoiceSettings.articleColumn)
                return;
            state.uploadInvoiceDialog.invoiceSettings.articleColumn.position = action.payload;
        },
        setInvoice: (state, action) => {
            state.uploadInvoiceDialog.invoice = action.payload.invoice;
            state.uploadInvoiceDialog.invoiceUploadErrorMessage = action.payload.uploadErrorMessage;
        },
        setActiveSetting: (state, action) => {
            state.uploadInvoiceDialog.activeSettingKey = action.payload;
        },
        bindSetting: (state, action) => {
            const { activeSettingKey, invoiceSettings } = state.uploadInvoiceDialog;
            if (!invoiceSettings)
                return;
            if (!state.uploadInvoiceDialog.invoiceSettings)
                return;
            switch (activeSettingKey) {
                case 'invoiceIdCell':
                case 'dateCell':
                    state.uploadInvoiceDialog.invoiceSettings[activeSettingKey] = action.payload;
                    break;
                case 'articleColumn':
                    state.uploadInvoiceDialog.invoiceSettings.articleColumn = {
                        ...(invoiceSettings.articleColumn ?? { position: DetailArticlePosition.WholeString }),
                        column: action.payload.column,
                    };
                    break;
                case 'startRow':
                    state.uploadInvoiceDialog.invoiceSettings.startRow = action.payload.row;
                    break;
                default:
                    state.uploadInvoiceDialog.invoiceSettings[activeSettingKey] = action.payload.column;
                    break;
            }
        },
    },
});
const selectInvoiceSettings = (state) => state.stockReceipt.uploadInvoiceDialog.invoiceSettings;
const selectActiveSettingKey = (state) => state.stockReceipt.uploadInvoiceDialog.activeSettingKey;
export const selectActiveSetting = createSelector(selectInvoiceSettings, selectActiveSettingKey, (settings, activeSettingKey) => {
    return settings?.[activeSettingKey];
});
export const selectAllSettingsDefined = createSelector(selectInvoiceSettings, (settings) => {
    return !Object.values(settings).some((setting) => setting === undefined);
});
export const { actions: stockReceiptActions, reducer: stockReceiptStateReducer } = slice;
