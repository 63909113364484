import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Button } from '@mui/material';
import { TableCell } from 'core/ui';
const TableRowCells = ({ columns, rowNumber, item }) => {
    return (_jsx(_Fragment, { children: columns.map((column, index) => column.isRowNumber ? (_jsx(TableCell, { children: rowNumber }, `cellNumber_${index}`)) : column.field ? (_jsx(TableCell, { align: column.align, children: item[column.field] }, `field_${column.field}`)) : column.element ? (_jsx(TableCell, { align: column.align, children: column.element }, `element_${index}`)) : column.button ? (_jsx(TableCell, { align: column.align, children: _jsx(Button, { ...column.button.props, onClick: (event) => {
                    column.button?.onClick(item);
                    event?.stopPropagation();
                }, onDoubleClick: (event) => event.stopPropagation() }) }, `button_${index}`)) : null) }));
};
export { TableRowCells };
