import { UploadStockInvoiceStepIndex } from 'warehouse/modules/receipt/stock/models';
import { createAppAsyncThunk } from 'warehouse/helpers';
import { enqueueSnackbar } from 'notistack';
import { getErrorFromException } from 'core/utils/errorHandling';
import { stockReceiptActions } from '../stockReceipt.state';
import { stockReceiptApi } from '../stockReceipt.api';
import { t } from 'shared/translations';
/**
 * Uploads invoice and gets parsed invoice data with settings.
 */
const uploadInvoiceAndGetSettings = createAppAsyncThunk('stockReceipt/uploadInvoiceAndGetSettings', async (invoice, { getState, dispatch, rejectWithValue }) => {
    let invoiceSettings;
    dispatch(stockReceiptActions.setIsInvoiceUploading(true));
    try {
        const parsedStockInvoice = await dispatch(stockReceiptApi.endpoints.uploadInvoice.initiate(invoice)).unwrap();
        dispatch(stockReceiptActions.setInvoice({ invoice: parsedStockInvoice }));
    }
    catch (error) {
        const uploadErrorMessage = getErrorFromException(error);
        dispatch(stockReceiptActions.setInvoice({ uploadErrorMessage }));
        enqueueSnackbar({
            variant: 'error',
            header: t('stock.receipt.invoice.upload.failed'),
            body: getErrorFromException(error),
            persist: true,
        });
        dispatch(stockReceiptActions.setIsInvoiceUploading(false));
        return;
    }
    try {
        invoiceSettings = await dispatch(stockReceiptApi.endpoints.getParsedInvoiceSettings.initiate(undefined, { forceRefetch: true })).unwrap();
    }
    catch (error) {
        enqueueSnackbar({
            variant: 'error',
            header: t('stock.receipt.get.parsed.invoice.settings.failed'),
            body: getErrorFromException(error),
            persist: true,
        });
        dispatch(stockReceiptActions.setIsInvoiceUploading(false));
        return;
    }
    dispatch(stockReceiptActions.setInvoiceSettings(invoiceSettings));
    dispatch(stockReceiptActions.setStep(UploadStockInvoiceStepIndex.CellMapping));
    dispatch(stockReceiptActions.setIsInvoiceUploading(false));
});
export { uploadInvoiceAndGetSettings };
